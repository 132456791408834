<template>
    <!-- 基本信息 -->
    <div>
        <el-container>
            <el-header  style="height:85px">
                <topHead :detalis='detalis' :detalisProps='detalisProps'/>
            </el-header>
            <el-main >
               <!-- 内容 -->
             <div class="card">
                   <!-- <success  v-if="process_id == 1"/> -->
                   <!-- 成功 -->
                   <adopt v-if="$route.query.process_id == 0"/>
                   <!-- 审核 -->
                   <success v-else/>
             </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import topHead from "./components/topHead.vue";
import success from "./components/success.vue";
import adopt from "./components/adopt.vue";
// import shenHe from './components/shenHe.vue'
export default {
  data() {
    return {
        detalis:null,
        flag:false,
        process_id:null,
        detalisProps:null
    };
  },
  components: {
    topHead,success,
    adopt
  },
  created() {
    this.getMgt()
    this.getData()
    console.log(this.$route.query)
    if(this.$route.query.process_id == 0){
        this.process_id = 0
    }
    if(this.$route.query.process_id == 1){
        this.process_id = 1
    }
  },
  methods: {
      // 获取信息
    getData(){
      let params = {
        serial_number: this.$route.query.k
      };
      this.$axios.postJSON("/base/invite/detail", params).then(res => {
            if (res.code == 200) {
              this.detalisProps = res.data;
            }
          });
    },

     // 获取设置
    getMgt(id){
      this.$axios.get('/setting/shortlisted',{company_id:this.$route.query.company_id}).then(res=>{
        if(res.code ==200){
          this.detalis = res.data.value
          console.log(this.detalis)
          this.flag = true
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;

  .el-header {
    // background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 85px;
    // width: 1200px;
    margin: auto;
    background-color: rgba(255, 255, 255, 100);
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.1);
   
  }
  .el-main {
    margin: auto;
    text-align: left;
    .card{
        // margin:30px 25px;
        width: 80%;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color:#fff;
        min-height: 800px;
        padding: 30px 70px;
        box-sizing: border-box;
    }
  }
}
</style>